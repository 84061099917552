import React, { useState, useEffect } from 'react';
import NCTextField from './Elements/TextFields';
import NCTextArea from './Elements/TextAreas';
import { Button } from './Elements/Button';
import axios from '../utils/axios';
import constants from '../utils/constants';
import { setLoadingDialog, setError } from '../store';
import { useSelector, useDispatch } from 'react-redux';


function AttributesRenderer({ project, reloadProject }) {

  const [attrs, setAttrs] = useState([]);

  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const renderAttr = (attr) => {
    if (attr.attr_type === 'single_selection_choices') {

    } else if (attr.attr_type === 'multiple_selection_choices') {

    } else if (attr.attr_type === 'short_text') {
      return <div style={{marginTop: '15px'}}>
        <NCTextField
          type="text"
          label={attr.title + (attr.is_required ? ' *' : ' (Optional)')}
          labelFontWeight='500'
          placeholder={attr.hint}
          verticalLabel
          outlined
          value={getTechAttrEntry(attr.id).answer}
          onChange={(e) => setTechAttrEntry(attr, e.target.value)}
        />
      </div>;
    } else if (attr.attr_type === 'long_text') {
      return <div style={{marginTop: '15px'}}>
        <NCTextArea
          type="text"
          label={attr.title + (attr.is_required ? ' *' : ' (Optional)')}
          labelFontWeight='500'
          placeholder={attr.hint}
          verticalLabel
          outlined
          rows="3"
          value={getTechAttrEntry(attr.id).answer}
          onChange={(e) => setTechAttrEntry(attr, e.target.value)}
        />
      </div>;
    }

    return <></>;
  };

  useEffect(() => {
    if (project === null) {
      return;
    }
    let genAttrs = [];
    for (let i = 0; i < project.technology.technology_attributes.length; i++) {
      genAttrs.push({
        technology_attribute_id: project.technology.technology_attributes[i].id,
        answer: '',
        technology_attribute_choice_id: null
      });
    }

    setAttrs(genAttrs);
  }, [project]);

  const getTechAttrEntry = (id) => {
    for (let i = 0; i < attrs.length; i++) {
      if (attrs[i].technology_attribute_id === id) {
        return Object.assign({}, attrs[i]);
      }
    }

    return {
      technology_attribute_id: id,
      answer: '',
      technology_attribute_choice_id: null,
    };
  };

  const setTechAttrEntry = (attr, value) => {
    let tmpAttrs = [...attrs];
    for (let i = 0; i < tmpAttrs.length; i++) {
      if (tmpAttrs[i].technology_attribute_id === attr.id) {
        let newAttr = Object.assign({}, tmpAttrs[i]);
        if (attr.attr_type !== 'single_selection_choices') {
          newAttr.answer = value;
          tmpAttrs[i] = newAttr;
        }
      }
    }
    setAttrs(tmpAttrs);
  };

  const isFormValid = () => {
    for (let i = 0; i < project.technology.technology_attributes.length; i++) {
      if (project.technology.technology_attributes[i].is_required) {
        if (project.technology.technology_attributes[i].attr_type !== 'single_selection_choices') {
          if (getTechAttrEntry(project.technology.technology_attributes[i].id).answer.length === 0) {
            return false;
          }
        } else {
          if (getTechAttrEntry(project.technology.technology_attributes[i].id).technology_attribute_choice_id === null) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const startExecuting = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      attrs: attrs,
    };

    dispatch(setLoadingDialog(true));
    axios.post('/projects/' + project.id + '/start_executing', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      if (reloadProject) {
        reloadProject();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, 'Something Wrong Happened, Please try again.'));
      } else {
        dispatch(setError(true, 'Something Wrong Happened, Please try again.'));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  return (
    <>
      {project.technology.technology_attributes.map((attr) => {
        return (
          <div key={'tech_attribute__' + attr.id}>
            {renderAttr(attr)}
          </div>
        );
      })}
      {
        project.technology.technology_attributes.length > 0 ?
        <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
          <Button color="var(--primary-color)" padding="13px 50px" fontSize="16px" borderRadius={20}
            dark
            disabled={!isFormValid()}
            onClick={() => startExecuting()}>
            Start Executing
          </Button>
        </div> : <></>
      }
    </>
  );
}

export default AttributesRenderer;
