import React from 'react';
import {
  ClosableContainer,
  ClosableText,
} from './NavBarClosableElements';
import { IconButton } from '../Elements/Button';
import { MdOutlineArrowBack, MdOutlineArrowForward } from 'react-icons/md';
import { useSelector } from 'react-redux';

const NavBarClosable = ({
  onCloseButtonClick,
  text,
  style=null
}) => {

  const isRTL = useSelector(state => state.isRTL);

  return (
    <>
      <ClosableContainer style={style}>
        <IconButton onClick={onCloseButtonClick} color='#fff'>
          {
            isRTL ? 
            <MdOutlineArrowForward /> :
            <MdOutlineArrowBack />
          }
        </IconButton>
        <ClosableText>
          {text.length <= 20 ? text : text.substring(0, 20) + '...' }
        </ClosableText>
      </ClosableContainer>
    </>
  );
};

export default NavBarClosable;
