import React from 'react';
import {
  NavigationBarContainer,
  NavigationBarDiv,
  MobileNavOverlay,
  NavBarLogoContainer,
  NavBarBottom
} from './Elements';
import neuralcode_logo_white from '../../assets/neuralcode_logo_white.png';
import {
  List,
  ListItemRR,
  ListItemIcon,
  ListItemContent,
  ListItemTitle
} from '../../components/Elements/List';
import UserMenu from '../../components/UserMenu';
import { useSelector } from 'react-redux';
import { Text } from '../../components/Elements/Typography';
import { GoProject } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBarClosable from '../../components/NavBarClosable';
import { BsPersonWorkspace } from "react-icons/bs";
import { IoCode } from "react-icons/io5";
// import { GrFlows } from "react-icons/gr";
// import { MdLockOutline } from "react-icons/md";


function NavigationBar({ isMobileBarOpen, setMobileBarOpen }) {

  const location = useLocation();
  const navigate = useNavigate();

  const currentProject = useSelector(state => state.currentProject);

  const getNavigationItems = () => {
    if (location.pathname.includes('projects/proj_')) {
      if (currentProject !== null) {
        return (
          <div style={{marginTop: '15px'}}>
            <NavBarClosable 
              text={currentProject.name}
              onCloseButtonClick={() => navigate('/')}
            />
  
            <List style={{marginTop: '15px'}}>
              <ListItemRR to={'/projects/' + currentProject.id} onClick={() => setMobileBarOpen(false)} borderRadius='10px'
                isDarkMode end>
                <ListItemIcon iconSize='23px' color={'#fff'}>
                  <BsPersonWorkspace />
                </ListItemIcon>
                <ListItemContent>
                  <ListItemTitle fontSize='14px' fontWeight="500" color={'#fff'}>
                    Workspace
                  </ListItemTitle>
                </ListItemContent>
              </ListItemRR>
              <ListItemRR to={'/projects/' + currentProject.id + '/source'} onClick={() => setMobileBarOpen(false)} borderRadius='10px'
                isDarkMode
                style={{marginTop: '5px'}}>
                <ListItemIcon iconSize='23px' color={'#fff'}>
                  <IoCode />
                </ListItemIcon>
                <ListItemContent>
                  <ListItemTitle fontSize='14px' fontWeight="500" color={'#fff'}>
                    Source Code
                  </ListItemTitle>
                </ListItemContent>
              </ListItemRR>
            </List>
          </div>
        );
      }
    } else {
      return (
        <List style={{marginTop: '15px'}}>
          <ListItemRR to={'/'} onClick={() => setMobileBarOpen(false)} borderRadius='10px'
            isDarkMode>
            <ListItemIcon iconSize='23px' color={'#fff'}>
              <GoProject />
            </ListItemIcon>
            <ListItemContent>
              <ListItemTitle fontSize='14px' fontWeight="500" color={'#fff'}>
                Projects
              </ListItemTitle>
            </ListItemContent>
          </ListItemRR>
          {/* <ListItemRR to={'/flows'} onClick={() => setMobileBarOpen(false)} borderRadius='10px'
            isDarkMode
            style={{marginTop: '5px'}}>
            <ListItemIcon iconSize='23px' color={'#fff'}>
              <GrFlows />
            </ListItemIcon>
            <ListItemContent>
              <ListItemTitle fontSize='14px' fontWeight="500" color={'#fff'}>
                Flows
              </ListItemTitle>
            </ListItemContent>
          </ListItemRR> */}
        </List>
      );
    }
  };

  return (
    <>
      <MobileNavOverlay isOpen={isMobileBarOpen} onClick={() => setMobileBarOpen(false)}></MobileNavOverlay>
      <NavigationBarContainer isDarkMode>
        <NavigationBarDiv>
          <NavBarLogoContainer to='/'>
            <img 
              src={neuralcode_logo_white}
              height={33}
              alt="Actipate"
              style={{pointerEvents: 'none', userSelect: 'none'}}
            />
          </NavBarLogoContainer>
          {getNavigationItems()}
          <NavBarBottom>
            <UserMenu />
          </NavBarBottom>
        </NavigationBarDiv>
      </NavigationBarContainer>
    </>
  )
}

export default NavigationBar;
