import React, { useState, useEffect } from 'react';
import { PageDiv, Container } from '../../layouts/Containers';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentProject, setError, setLoadingDialog } from '../../store';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { useParams, Routes, Route } from 'react-router-dom';
import { Text } from '../../components/Elements/Typography';
import { FlexSpacer, Divider } from '../../components/Elements/SmallElements';
import { IconButton } from '../../components/Elements/Button';
import { MdDelete, MdEdit, MdFileDownload } from 'react-icons/md';
import Workspace from './Workspace';
import RedirectTo from '../../components/RedirectTo';
import { openInNewTab } from '../../utils/helpers';
import Source from './Source';
import ProjectDialog from '../../components/DataDialogs/ProjectDialog';
import YesNoDialog from '../../components/Elements/YesNoDialog';
import { useNavigate } from 'react-router-dom';


function ProjectViewer() {

  const [project, setProject] = useState(null);

  const [isProjectDialogOpen, setProjectDialogOpen] = useState(false);
  const [isDeleteProjectDialogOpen, setDeleteProjectDialogOpen] = useState(false);

  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const { pid } = useParams();

  const navigate = useNavigate();

  const setPageTitle = () => {
    if (project === null){
      return;
    }

    document.title = project.name + ' | ' + constants.APP_NAME;
  }

  const getProject = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/projects/' + pid, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setProject(response.data);
      dispatch(setCurrentProject({id: response.data.id, name: response.data.name}));
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getProject();
  }, []);

  useEffect(() => {
    setPageTitle();
  }, [project]);

  const deleteProject = () => {
    dispatch(setLoadingDialog(true));
    axios.delete('/projects/' + pid, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      navigate('/');
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      } else {
        dispatch(setError(true, constants.GENERAL_ERROR));
        setTimeout(() => {
          dispatch(setError(false, ''));
        }, 3000);
      }
    });
  }

  return (
    <>
      {
        project !== null ? (
          <PageDiv>
            <ProjectDialog
              isOpen={isProjectDialogOpen}
              setOpen={setProjectDialogOpen}
              onSuccess={getProject}
              projectObj={Object.assign({}, project)}
            />
            <YesNoDialog
              isOpen={isDeleteProjectDialogOpen}
              setOpen={setDeleteProjectDialogOpen}
              title='Delete Project'
              text='Are you sure that you want to delete this project?'
              onYesClicked={deleteProject}
            />
            <Container fluid style={{position: 'relative'}}>
              <div style={{display: 'flex', alignItems: 'center', padding: '20px 0px'}}>
                <Text fontSize='22px' fontWeight='600' color='#fff' style={{marginTop: '10px'}}>
                  {project.name}
                </Text>
                <FlexSpacer isRTL={false} />
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <IconButton iconSize='27px' color='#fff' style={{margin: '0 5px'}} dark
                    disabled={project.project_files === null}
                    onClick={() => openInNewTab(project.project_files)}>
                    <MdFileDownload />
                  </IconButton>
                  <IconButton iconSize='27px' color='#fff' style={{margin: '0 5px'}} dark
                    onClick={() => setProjectDialogOpen(true)}>
                    <MdEdit />
                  </IconButton>
                  <IconButton iconSize='27px' color='#fff' style={{margin: '0 5px'}} dark
                    disabled={project.project_status === 'in_progress'}
                    onClick={() => setDeleteProjectDialogOpen(true)}>
                    <MdDelete />
                  </IconButton>
                </div>
              </div>
              <Routes>
                <Route path='/' element={<Workspace project={project} reloadProject={getProject} />} />
                <Route path='/source' element={<Source project={project} />} />
                <Route path="*" element={<RedirectTo linkType='router' to={'/projects/' + pid} redirectType='replace' />} />
              </Routes>
            </Container>
          </PageDiv>
        ) : <></>
      }
    </>
  );
}

export default ProjectViewer;
