import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import axios from 'axios';
// import { CodeiumEditor } from '@codeium/react-code-editor';
import MonacoEditor from './MonacoEditor';
import DirectoryTree from './DirectoryTree';

const ProjectEditor = ({ sourceCode }) => {
  const [zip, setZip] = useState(null); // Store the zip instance
  const [directoryStructure, setDirectoryStructure] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    fetchAndExtractZip();
  }, []);

  const fetchAndExtractZip = async () => {
    try {
      const response = await axios.get(sourceCode, { responseType: 'arraybuffer' });
      const zipInstance = new JSZip();
      const zipContents = await zipInstance.loadAsync(response.data);

      // Store the zip instance for future use
      setZip(zipInstance);

      // Build directory structure
      const buildFileTree = (zipFiles) => {
        const tree = {};
        Object.keys(zipFiles).forEach((filePath) => {
          const parts = filePath.split('/');
          let current = tree;

          parts.forEach((part, index) => {
            if (!part) return;
            if (index === parts.length - 1) {
              current[part] = { type: 'file', path: filePath };
            } else {
              current[part] = current[part] || { type: 'directory', children: {} };
              current = current[part].children;
            }
          });
        });

        const convertToArray = (obj) =>
          Object.entries(obj).map(([name, value]) => ({
            ...value,
            name,
            children: value.children ? convertToArray(value.children) : null,
          }));

        return convertToArray(tree);
      };

      const structure = buildFileTree(zipContents.files);
      setDirectoryStructure(structure);
    } catch (error) {
      console.error("Error fetching or extracting zip:", error);
    }
  };

  const handleFileSelect = async (file) => {
    try {
      setSelectedFile(file);

      if (!zip) {
        console.error("Zip instance is not loaded yet!");
        return;
      }

      const fileEntry = zip.file(file.path);

      if (!fileEntry) {
        console.error(`File not found in zip: ${file.path}`);
        return;
      }

      const content = await fileEntry.async("string");
      setFileContent(content);
    } catch (error) {
      console.error("Error reading file content:", error);
    }
  };

  return (
    <div style={{ display: 'flex', backgroundColor: 'var(--code-editor-background)', minHeight: '85vh' }}>
      {/* Directory Tree */}
      <div style={{ width: '20%', height: '100%' }}>
        <DirectoryTree structure={directoryStructure} onSelect={handleFileSelect} selectedFile={selectedFile} />
      </div>
      <div style={{ width: '80%', height: '100%' }}>
        {selectedFile ? (
          <MonacoEditor
            fileName={selectedFile.name}
            initialContent={fileContent}
            // onContentChange={(newValue) => setFileContent(newValue)}
          />
        ) : (
          <MonacoEditor
            fileName={'Untitled'}
            initialContent={''}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectEditor;
