import React, { useEffect, useState } from 'react';
import ProjectEditor from '../../components/ProjectEditor';
import { Text } from '../../components/Elements/Typography';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { setLoadingDialog } from '../../store';
import { useDispatch, useSelector } from 'react-redux';

function Source({ project }) {

  const [sourceCode, setSourceCode] = useState(null);

  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const getSourceCode = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/projects/' + project.id + '/source', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setSourceCode(response.data.project_files);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    getSourceCode();
  }, []);

  return (
    <>
      {
        sourceCode !== null ?
        <ProjectEditor sourceCode={sourceCode} /> : <></>
      }
      {
        sourceCode === null ? 
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '80px'}}>
          <div style={{background: '#000', padding: '20px 30px'}}>
            <Text color='rgba(255, 255, 255, 0.7)' fontSize='18px' fontWeight='300'>
              Go to Workspace.
            </Text>
          </div>
        </div> : <></>
      }
    </>
  );
}

export default Source;
