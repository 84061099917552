import React from 'react';
import { ProjectCardDiv, CardContent } from './Elements';
import { Image } from '../Elements/Image';
import { Text } from '../Elements/Typography';
import { Chip } from '../Elements/Chip';
import { useNavigate } from 'react-router-dom';

function ProjectCard({ project }) {

  const navigate = useNavigate();

  return (
    <>
      <ProjectCardDiv onClick={() => navigate('/projects/' + project.id)}>
        <Image
          src={project.image !== null ? project.image.thumb_lg : project.technology.image !== null ? project.technology.image.thumb_lg : null}
          height='200px'
          alt={project.name}
        />
        <CardContent>
          <Text fontSize='16px' fontWeight='400' color='#fff'>
            {project.name}
          </Text>
          <div style={{display: 'flex', marginTop: '7px'}}>
            <Chip borderRadius={20} padding="7px 15px" fontSize="13px" color="var(--secondary-color)" fontWeight="500">
              {project.technology.name}
            </Chip>
          </div>
        </CardContent>
      </ProjectCardDiv>
    </>
  );
}

export default ProjectCard;
