import React, { useState, useEffect } from 'react';
import Dialog from '../Elements/Dialog';
import { setLoadingDialog, setSuccess, setError } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { FlexSpacer } from '../Elements/SmallElements';
import { Button } from '../Elements/Button';
import NCTextField from '../Elements/TextFields';
import NCTextArea from '../Elements/TextAreas';
import NCSelect from '../Elements/Selects';
import { getBadRequestErrorMessage } from '../../utils/helpers';


function ProjectDialog({ isOpen, setOpen, projectObj=null, onSuccess }) {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTechnology, setSelectedTechnology] = useState('');

  const [technologies, setTechnologies] = useState([]);

  const accessToken = useSelector(state => state.accessToken);
  
  const dispatch = useDispatch();

  const clearData = () => {
    setName('');
    setDescription('');
    setSelectedTechnology(null);
  };

  const setDialogOpen = (open) => {
    if (!open) {
      clearData();
    }
    setOpen(open);
  };

  const isFormValid = () => {
    let isValid = name.length > 0;

    if (projectObj === null) {
      isValid = isValid && selectedTechnology !== null;
    }

    return isValid;
  };

  const getTechnologies = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/projects/express_technologies', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setTechnologies(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getTechnologies();
  }, [])

  const addProject = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      name: name,
      description: description,
      technology_id: selectedTechnology,
    };

    dispatch(setLoadingDialog(true));
    axios.post('/projects', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      dispatch(setSuccess(true, 'Action Executed Successfully!'));
      setTimeout(() => {
        dispatch(setSuccess(false, ''));
      }, 3000);
      setDialogOpen(false);
      if (onSuccess !== null) {
        onSuccess();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, getBadRequestErrorMessage(error.response.data)));
      } else {
        dispatch(setError(true, constants.GENERAL_ERROR));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  const editProject = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      name: name,
      description: description,
    };

    dispatch(setLoadingDialog(true));
    axios.put('/projects/' + projectObj.id, data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      dispatch(setSuccess(true, 'Action Executed Successfully!'));
      setTimeout(() => {
        dispatch(setSuccess(false, ''));
      }, 3000);
      setDialogOpen(false);
      if (onSuccess !== null) {
        onSuccess();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, getBadRequestErrorMessage(error.response.data)));
      } else {
        dispatch(setError(true, constants.GENERAL_ERROR));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  const onConfirmClick = () => {
    if (projectObj === null) {
      addProject();
    } else {
      editProject();
    }
  };

  useEffect(() => {
    if (projectObj !== null) {
      setName(projectObj.name);
      setDescription(projectObj.description);
    } else {
      clearData();
    }
  }, [projectObj]);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        setOpen={setDialogOpen}
        maxWidth="500px"
        title={projectObj === null ? 'Create a Project' : 'Edit Project'}
        padding='10px 15px'
        child={
          <>
            <div>
              <NCTextField
                type="text"
                label='Name'
                labelFontWeight='500'
                verticalLabel
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <NCTextArea
                type="text"
                label='Description (Optional)'
                labelFontWeight='500'
                verticalLabel
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {
              projectObj === null ? 
              <div style={{marginTop: '10px'}}>
                <NCSelect
                  label={'Technology'}
                  labelFontWeight='500'
                  verticalLabel
                  items={technologies}
                  value={selectedTechnology}
                  itemValue='id'
                  itemText='name'
                  onChange={(value) => setSelectedTechnology(value)}
                />
              </div> : <></>
            }
          </>
        }
        actions={
          <>
            <FlexSpacer />
            <Button borderRadius={7} padding="10px 15px" dark disabled={!isFormValid()}
              onClick={onConfirmClick}>
              Confirm
            </Button>
          </>
        }
      />
    </>
  );
}

export default ProjectDialog;
