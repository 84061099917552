import React from 'react';
import styled from 'styled-components';
import AttributesRenderer from '../../components/AttributesRenderer';
import ProjectChat from '../../components/ProjectChat';


const ProjectAttributesCard = styled.div`
  background: var(--card-color);
  width: 100%;
  position: relative;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
  min-height: 300px;
  padding-bottom: 15px;
  border-radius: 10px;
`

function Workspace({ project, reloadProject }) {
  return (
    <>
      {
        project.project_status === 'pending' ?
        <ProjectAttributesCard>
          <div style={{padding: '10px 20px'}}>
            <AttributesRenderer project={project} reloadProject={reloadProject} />
          </div>
        </ProjectAttributesCard> : <></>
      }
      {
        project.project_status !== 'pending' ?
        <ProjectChat project={project} reloadProject={reloadProject} /> : <></>
      }
    </>
  );
}

export default Workspace;
