import styled from 'styled-components';

export const ConversationCard = styled.div`
  background: var(--page-background);
  width: 100%;
  height: 85vh;
  padding-bottom: 15px;
  position: relative;
  margin-top: -8px;
`

export const SendingContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--card-color);
  border-radius: 20px;
  z-index: 1;
  padding: 10px;
`

export const MessagesContainer = styled.div`
  height: 64vh;
  overflow: auto;
`

export const MessageItemContainer = styled.div`
  display: flex;
  justify-content: ${props => props.fromMe ? 'flex-end' : 'flex-start'};
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
`

export const MessageItem = styled.div`
  width: 60%;
  min-height: 50px;
  display: inline-block;
  margin: 0 5px;
  padding: 14px 14px;
  border-radius: 20px;
  background: ${props => props.fromMe ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)'};
`