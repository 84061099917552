import styled from 'styled-components';

export const ProjectCardDiv = styled.div`
  position: relative;
  height: 100%;
  background: var(--card-color);
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
`

export const CardContent = styled.div`
  border: none;
  padding: 10px;
`