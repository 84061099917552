import styled from 'styled-components';
import breakpoint from '../../utils/breakpoint';
import { NavLink as LinkRR } from 'react-router-dom';

export const NavigationBarContainer = styled.div`
  min-height: 100vh;
  background: var(--navigation-bar-color);
  width: 290px;
  position: sticky;
  top: 0;
  flex: none;
  z-index: 50;
  transition: 0.3s ease-in-out;

  @media screen and (${breakpoint.devices_max.sm}) {
    position: fixed;
    opacity: ${props => props.isOpen ? '100%' : '0%'};
    top: 0;
    left: ${props => props.isRTL ? 'unset' : (props.isOpen ? '0' : '-100%')};
    right: ${props => props.isRTL ? (props.isOpen ? '0' : '-100%') : 'unset'};
    z-index: 150;
    height: 100vh;
    overflow: auto;
  }
`

export const NavigationBarDiv = styled.div`
  padding: 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (${breakpoint.devices_max.sm}) {
    padding-top: 15px;
  }
`

export const MobileNavOverlay = styled.div`
  display: none;

  @media screen and (${breakpoint.devices_max.sm}) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.02);
    z-index: 90;
  }
`

export const NavBarLogoContainer = styled(LinkRR)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  text-decoration: none;
  user-select: none;
`

export const NavBarBottom = styled.div`
  margin-top: auto;
`
