import React, { useState } from 'react';
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { ListItem, ListItemContent, ListItemIcon, ListItemTitle } from './Elements/List';

const DirectoryTree = ({ structure, onSelect, selectedFile }) => {
  return (
    <div style={{height: '100%', padding: '10px', listStyle: 'none'}}>
      {structure.map((item) => (
        <TreeNode key={item.path} node={item} onSelect={onSelect} selectedFile={selectedFile} />
      ))}
    </div>
  );
};

const TreeNode = ({ node, onSelect, selectedFile }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    if (node.type === 'directory') {
      setExpanded(!expanded);
    } else {
      onSelect(node);
    }
  };

  return (
    <>
      <div>
        <ListItem padding='5px' clickable onClick={handleToggle} isDarkMode active={selectedFile && selectedFile.path === node.path}>
          {node.type === 'directory' ? <ListItemIcon iconSize='18px' color='#fff'>
            {expanded ? <GoChevronDown /> : <GoChevronRight />}
          </ListItemIcon> : <></>}
          <ListItemContent>
            <ListItemTitle color='#fff' fontSize='14px' fontWeight='300'>
              {node.name}
            </ListItemTitle>
          </ListItemContent>
        </ListItem>
        {expanded && node.children && (
          <div style={{paddingLeft: '10px'}}>
            {node.children.map((child) => (
              <TreeNode key={child.path} node={child} onSelect={onSelect} selectedFile={selectedFile} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DirectoryTree;
