const SERVER_DNS = process.env.REACT_APP_PROTOCOL + '://' + process.env.REACT_APP_DNS;
const WEBSOCKET_DNS = process.env.REACT_APP_WEBSOCKET_PROTOCOL + '://' + process.env.REACT_APP_DNS;

const constants = {
  BASE_URL: SERVER_DNS + '/clients',
  WEBSOCKET_URL: WEBSOCKET_DNS + '/clients',
  API_KEY: process.env.REACT_APP_API_KEY,
  APP_NAME: 'NeuralCode',
  REFRESH_TOKEN_LOCALSTORAGE: 'NC_REF_TOK',
  COOKIES_DOMAIN_NAME: 'app.useneuralcode.com',
  NEURALCODE_LINK: 'https://www.useneuralcode.com',
  GENERAL_ERROR: 'Something wrong happened, please try again.',
  cookies: {
    LANG: '_NC_LOCALE',
    TOKEN: '_NC_TOK',
  },
  status: {
    INTERNAL_SERVER_ERROR: 500,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    BAD_REQUEST: 400
  }
};

export default constants;
