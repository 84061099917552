import React from 'react';
import {
  CodeGenerationIndicatorContainer
} from './CodeGenerationIndicatorElements';
import { Text } from '../Elements/Typography';
import { TailSpin } from 'react-loader-spinner';

function CodeGenerationIndicator({ text }) {
  return (
    <>
      <CodeGenerationIndicatorContainer>
        <TailSpin
          color='#fff'
          height={30}
          width={30}
        />
        <Text color='#fff' fontSize='16px' fontWeight='500' style={{margin: '0 10px'}}>
          {text}
        </Text>
      </CodeGenerationIndicatorContainer>
    </>
  );
}

export default CodeGenerationIndicator;
