import React, { useState, useEffect } from 'react';
import { PageDiv, Container, Row, Column } from '../layouts/Containers';
import breakpoint from '../utils/breakpoint';
import { Text } from '../components/Elements/Typography';
import { Button, BtnIcon } from '../components/Elements/Button';
import { Divider, FlexSpacer } from '../components/Elements/SmallElements';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import { setLoadingDialog } from '../store';
import axios from '../utils/axios';
import constants from '../utils/constants';
import ProjectCard from '../components/ProjectCard';
import ProjectDialog from '../components/DataDialogs/ProjectDialog';


function Projects() {

  const [projects, setProjects] = useState([]);

  const [isProjectDialogOpen, setProjectDialogOpen] = useState(false);

  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const setPageTitle = () => {
    document.title = 'Projects | ' + constants.APP_NAME;
  }

  const getProjects = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/projects', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setProjects(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    setPageTitle();
    getProjects();
  }, []);

  const createProject = () => {
    setProjectDialogOpen(true);
  };

  return (
    <>
      <ProjectDialog
        isOpen={isProjectDialogOpen}
        setOpen={setProjectDialogOpen}
        onSuccess={getProjects}  
      />
      <PageDiv>
        <Container fluid>
          <div style={{display: 'flex', alignItems: 'center', padding: '20px 0px'}}>
            <Text fontSize='22px' fontWeight='600' color='#fff' style={{marginTop: '10px'}}>
              Projects
            </Text>
            <FlexSpacer isRTL={false} />
            <Button color="var(--primary-color)" dark padding="10px 20px" borderRadius={10}
              onClick={() => createProject()}>
              <BtnIcon left={breakpoint.checkers.smAndUp()} color="white"
                iconSize={breakpoint.checkers.smAndUp() ? '23px' : '26px'}>
                <HiOutlinePlusCircle />
              </BtnIcon>
              {
                breakpoint.checkers.smAndUp() ? 
                <span>
                  Create a New Project
                </span> : <></>
              }
            </Button>
          </div>
          <Divider color='rgba(255,255,255,0.2)' />
          <Row style={{marginTop: '10px'}}>
            {projects.map((project) => {
              return (
                <Column key={'project_' + project.id} cols={4} lg={4} md={4} sm={6} xs={12}>
                  <ProjectCard project={project} />
                </Column>
              );
            })}
          </Row>
          {
            projects.length === 0 ? 
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '80px'}}>
              <div style={{background: '#000', padding: '20px 30px'}}>
                <Text color='rgba(255, 255, 255, 0.7)' fontSize='18px' fontWeight='300'>
                  You currently have no projects
                </Text>
              </div>
            </div> : <></>
          }
        </Container>
      </PageDiv>
    </>
  )
}

export default Projects;
