import React, { useRef, useEffect, useState } from 'react';
import * as monaco from 'monaco-editor';
import { getLanguageFromExtension } from '../utils/helpers';

const MonacoEditor = ({ fileName, initialContent, onContentChange, theme='vs-dark' }) => {
  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (containerRef.current) {
      const newEditor = monaco.editor.create(containerRef.current, {
        value: initialContent,
        language: getLanguageFromExtension(fileName), // Automatically set language
        theme: theme || "vs-dark", // Set theme (dark theme as default)
        automaticLayout: true, // Adjust editor layout automatically
        minimap: {
          enabled: true, // Enable minimap
        },
        lineNumbers: "on", // Show line numbers
        wordWrap: "on", // Enable word wrapping
        folding: true, // Enable code folding
        scrollBeyondLastLine: false, // Prevent scrolling beyond the last line
        smoothScrolling: true, // Smooth scrolling for better experience
        matchBrackets: "always", // Highlight matching brackets
        scrollbar: {
          verticalScrollbarSize: 10, // Customize scrollbar size
          horizontalScrollbarSize: 10,
        },
        suggestOnTriggerCharacters: true, // Enable IntelliSense suggestions
        quickSuggestions: {
          other: true,
          comments: true,
          strings: true,
        },
        overviewRulerBorder: true, // Add a border to the overview ruler
      });

      setEditor(newEditor);
      editorRef.current = newEditor;

      // Handle content change
      newEditor.onDidChangeModelContent(() => {
        if (onContentChange) {
          onContentChange(newEditor.getValue());
        }
      });
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.dispose();
      }
    };
  }, [fileName, initialContent]);

  return <div ref={containerRef} style={{ minHeight: "85vh", width: "100%" }} />;
};

export default MonacoEditor;
