import styled from 'styled-components';

export const ClosableContainer = styled.div`
  background: rgba(255, 255, 255, 0.03);
  padding: 5px 8px;
  border: thin solid rgba(255,255,255,0.12);
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export const ClosableText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 10px 10px;
`
