import React from 'react';
import { LoadingDialogContainer, LoadingDialogOverlay } from './LoadingDialogElements';
import { TailSpin } from "react-loader-spinner";

function LoadingDialog() {
  return (
    <>
      <LoadingDialogOverlay />
      <LoadingDialogContainer>
        <TailSpin
          color="var(--secondary-color)"
          height={100}
          width={100}
        />
      </LoadingDialogContainer>
    </>
  );
}

export default LoadingDialog;
