import React, { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import LoadingDialog from './components/LoadingDialog';
import FullLoading from './components/FullLoading';
import constants from './utils/constants';
import MessageBar from './components/Elements/MessageBar';
import { setAppLoading, setUser, setAccessToken } from './store';
import RedirectTo from './components/RedirectTo';
import axios from './utils/axios';
import { refreshToken } from './utils/helpers';

import {
  AppMainContainer,
  Container,
  AppTheme
} from './layouts/Containers';
import NavigationBar from './layouts/NavigationBar';

// Outer Views
import Login from './views/OuterViews/Login';
import SignUp from './views/OuterViews/SignUp';

// Inner Views
import Projects from './views/Projects';
import ProjectViewer from './views/ProjectViewer';

function App() {

  const isAppLoading = useSelector(state => state.isAppLoading);
  const isFullLoading = useSelector(state => state.isFullLoading);
  const isLoadingDialog = useSelector(state => state.isLoadingDialog);
  const accessToken = useSelector(state => state.accessToken);
  const isError = useSelector(state => state.isError);
  const errorMessage = useSelector(state => state.errorMessage);
  const isSuccess = useSelector(state => state.isSuccess);
  const successMsg = useSelector(state => state.successMsg);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies([constants.cookies.LANG, constants.cookies.TOKEN]);
  const [_windowDims, setWindowDims] = useState();

  const [isMobileBarOpen, setMobileBarOpen] = useState(false);

  const handleResize = () => {
    setWindowDims({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const cookiesAccessToken = cookies[constants.cookies.TOKEN];
    if (cookiesAccessToken !== undefined && cookiesAccessToken !== null) {
      dispatch(setAccessToken(cookiesAccessToken));
      getUserInfo(cookiesAccessToken);
    } else {
      dispatch(setAppLoading(false));
    }
  }, []);

  const getUserInfo = (accessToken) => {
    dispatch(setAppLoading(true));
    axios.get('/auth/user_info', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setUser(response.data));
      dispatch(setAppLoading(false));
    }).catch((error) => {
      if (error.response.status === constants.status.UNAUTHORIZED) {
        refreshToken(setCookie, removeCookie);
      } else {
        dispatch(setAppLoading(false));
      }
    });
  };

  return (
    <>
      {
        isFullLoading ? <FullLoading /> : <></>
      }
      {
        isLoadingDialog ? <LoadingDialog /> : <></>
      }
      {
        isAppLoading ? <FullLoading /> :
        <Router>
          {
            isError ? <MessageBar message={errorMessage} backgroundColor='var(--danger-color)' /> : <></>
          }
          {
            isSuccess ? <MessageBar message={successMsg} backgroundColor='var(--success-color)' /> : <></>
          }
          {
            accessToken !== null ? (
              <AppMainContainer>
                <NavigationBar isMobileBarOpen={isMobileBarOpen} setMobileBarOpen={setMobileBarOpen} />
                <Container>
                  <Routes>
                    <Route path="/" element={<Projects />} />
                    {/* <Route path='/flows' element={<div />} /> */}
                    <Route path="projects/:pid/*" element={<ProjectViewer />} />
                    <Route path="*" element={<RedirectTo linkType="router" to="/" redirectType="replace" />} />
                  </Routes>
                </Container>
              </AppMainContainer>
            ) : (
              <Routes>
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="*" element={<RedirectTo linkType="router" to="/login" redirectType="replace" />} />
              </Routes>
            )
          }
        </Router>
      }
    </>
  );
}

export default App;
