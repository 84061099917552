import styled from 'styled-components';

export const CodeGenerationIndicatorContainer = styled.div`
  background: var(--card-color);
  border-radius: 20px;
  padding: 10px 15px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.6), 0 2px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 100;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`
