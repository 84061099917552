import React, { useState, useEffect, useRef } from 'react';
import {
  ConversationCard,
  SendingContainer,
  MessagesContainer,
  MessageItemContainer,
  MessageItem
} from './ProjectChatElements';
import NCTextArea from '../Elements/TextAreas';
import { FlexSpacer } from '../Elements/SmallElements';
import { IconButton } from '../Elements/Button';
import { FaArrowAltCircleUp } from "react-icons/fa";
import { Text } from '../Elements/Typography';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { setLoadingDialog, setSuccess } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getLanguageFromExtension } from '../../utils/helpers';
import { Oval } from "react-loader-spinner";
import CodeGenerationIndicator from '../CodeGenerationIndicator';


function ProjectChat({ project, reloadProject }) {

  const [llmChats, setLLMChats] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [stopScrollingBehaviour, setStopScrollingBehaviour] = useState(false);
  const [currentTaskText, setCurrentTaskText] = useState('');
  const [isStreaming, setStreaming] = useState(false);
  const [isSendingMessage, setSendingMessage] = useState(false);

  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const messagesEndRef = useRef();

  const webSocket = useRef(null);

  const getLLMChats = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/projects/' + project.id + '/llm_chats', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setLLMChats(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getLLMChats();
  }, []);

  const sendMessage = () => {
    if (isStreaming || project.project_status === 'in_progress' || isSendingMessage) {
      return;
    }

    const data = {
      text: messageText,
    };
    setMessageText('');
    setSendingMessage(true);
    axios.post('/projects/' + project.id + '/send_message', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setSendingMessage(false);
      if (response.data.user_request_type === 'project_modification') {
        if (reloadProject) {
          reloadProject();
        }
      }
    }).catch((error) => {
      setSendingMessage(false);
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  };

  const handleTextEnterKey = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const getSyntaxtHighlighterText = (lines) => {
    let text = '';
    for (let i = 0; i < lines.length; i++) {
      if (i === 0) {
        continue;
      }
      text += lines[i] + '\n';
    }
    return text;
  };

  const renderChatText = (text) => {
    if (text.includes('```')) {
      let arr = text.split('```');
      return (
        <>
          <Text
            fontSize='13px'
            fontWeight='500'
            color='#fff'
            style={{whiteSpace: 'pre-wrap'}}>
            {arr[0]}
          </Text>
          <div style={{marginTop: '5px'}}>
            <SyntaxHighlighter wrapLongLines language={arr[1].split('\n')[0]} style={vscDarkPlus}>
              {getSyntaxtHighlighterText(arr[1].split('\n'))}
            </SyntaxHighlighter>
          </div>
        </>
      );
    } else {
      return (
        <Text
          fontSize='15px'
          fontWeight='400'
          color='#fff'
          style={{whiteSpace: 'pre-wrap'}}>
          {text}
        </Text>
      );
    }
  };

  const getMessageBody = (chat, i) => {
    if (chat.is_system_level) {
      if (chat.llm_chat_type === 'from_user') {
        return (
          <MessageItemContainer fromMe={chat.llm_chat_type !== 'from_ai'}>
            <MessageItem fromMe={chat.llm_chat_type !== 'from_ai'}>
              <Text
                fontSize='15px'
                fontWeight='400'
                color='#fff'
                style={{whiteSpace: 'pre-wrap'}}>
                {chat.frontend_text}
              </Text>
            </MessageItem>
          </MessageItemContainer>
        );
      } else {
        if (chat.ai_response_type === 'tasks') {
          const tasks = JSON.parse(chat.text).tasks;
          return (
            <MessageItemContainer fromMe={chat.llm_chat_type !== 'from_ai'}>
              <MessageItem fromMe={chat.llm_chat_type !== 'from_ai'}>
                <Text fontSize='16px' color='#fff' fontWeight='600'>
                  Project Outline
                </Text>
                <div style={{marginTop: '8px', marginLeft: '10px', marginRight: '10px'}}>
                  {tasks.map((task, i) => {
                    return (
                      <Text fontSize='15px' color='#fff' fontWeight='400' key={'task__' + i}>
                        {i + 1}. {task.task_title}
                      </Text>
                    );
                  })}
                </div>
              </MessageItem>
            </MessageItemContainer>
          );
        } else if (chat.ai_response_type === 'subtasks') {
          const subtasks = JSON.parse(chat.text).subtasks;
          if (subtasks === null || subtasks === undefined) {
            return <></>;
          }
          return (
            <>
              {subtasks.map((subtask) => {
                return (
                  <MessageItemContainer fromMe={chat.llm_chat_type !== 'from_ai'}>
                    <MessageItem fromMe={chat.llm_chat_type !== 'from_ai'}>
                      <Text fontSize='13px' color='#fff' fontWeight='500'>
                        {subtask.subtask_type === 'bash' ? 'Bash' : subtask.file_path}
                      </Text>
                      <div style={{marginTop: '5px', width: '100%', maxWidth: '50vw'}}>
                        <SyntaxHighlighter language={subtask.subtask_type === 'bash' ? 'bash' : getLanguageFromExtension(subtask.file_path)} style={vscDarkPlus}>
                          {subtask.subtask_type === 'bash' ? subtask.bash_script : subtask.source_code}
                        </SyntaxHighlighter>
                      </div>
                    </MessageItem>
                  </MessageItemContainer>
                );
              })}
            </>
          );
        } else if (chat.ai_response_type === 'user_chat') {
          const userChatResponse = JSON.parse(chat.text);
          if (userChatResponse === null || userChatResponse === undefined) {
            return <></>;
          }
          return (
            <MessageItemContainer fromMe={chat.llm_chat_type !== 'from_ai'}>
              <MessageItem fromMe={chat.llm_chat_type !== 'from_ai'}>
                <Text fontSize='15px' color='#fff' fontWeight='400'>
                  {userChatResponse.response}
                </Text>
                {
                  userChatResponse.user_request_type === 'project_modification' ?
                  <>
                    <Text fontSize='16px' color='#fff' fontWeight='600' style={{marginTop: '10px'}}>
                      Task Outline
                    </Text>
                    <div style={{marginTop: '8px', marginLeft: '10px', marginRight: '10px'}}>
                      {userChatResponse.tasks.map((task, i) => {
                        return (
                          <Text fontSize='15px' color='#fff' fontWeight='400' key={'task__' + i}>
                            {i + 1}. {task.task_title}
                          </Text>
                        );
                      })}
                    </div>
                  </> : <></>
                }
              </MessageItem>
            </MessageItemContainer>
          );
        }
      }
    } else {
      return (
        <MessageItemContainer fromMe={chat.llm_chat_type !== 'from_ai'}>
          <MessageItem fromMe={chat.llm_chat_type !== 'from_ai'}>
            {renderChatText(chat.text)}
          </MessageItem>
        </MessageItemContainer>
      );
    }

    return <></>;
  };

  useEffect(() => {
    if (llmChats.length > 0 && !stopScrollingBehaviour) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [llmChats]);

  useEffect(() => {
    webSocket.current = new WebSocket(constants.WEBSOCKET_URL + '/projects/ws/' + project.id + 
      '/llm_chats?access_token=' + accessToken);

    let streamingMessage = null;
    
    webSocket.current.onmessage = (e) => {
      console.log('Received message');
      const data = JSON.parse(e.data);
      if (!data){
        return;
      }

      if (data.type === 'streaming_start') {
        // Initialize a new streaming message
        streamingMessage = {
          ...data.base_chat,
          text: '',
          is_complete: false
        };
        setLLMChats((_llmChats) => [..._llmChats, streamingMessage]);
        setStreaming(true);
      } else if (data.type === 'streaming_chunk') {
        // Update the last message with new chunk
        setLLMChats((_llmChats) => {
          const updatedChats = [..._llmChats];
          const lastMessageIndex = updatedChats.length - 1;
          
          if (lastMessageIndex >= 0) {
            updatedChats[lastMessageIndex] = {
              ...updatedChats[lastMessageIndex],
              text: updatedChats[lastMessageIndex].text + data.chunk
            };
          }
          
          return updatedChats;
        });
      } else if (data.type === 'streaming_end') {
        // Mark the message as complete
        setLLMChats((_llmChats) => {
          const updatedChats = [..._llmChats];
          const lastMessageIndex = updatedChats.length - 1;
          
          if (lastMessageIndex >= 0) {
            updatedChats[lastMessageIndex] = {
              ...updatedChats[lastMessageIndex],
              is_complete: true
            };
          }
          
          return updatedChats;
        });
        setStreaming(false);
      } else if (data.type === 'executing_task') {
        setCurrentTaskText(data.task_present_tense);
      } else if (data.type === 'tasks_completed') {
        if (reloadProject) {
          reloadProject();
        }
        dispatch(setSuccess(true, 'NeuralCode Agent Finished The Job, You Can View The Source Code Now'));
        setTimeout(() => {
          dispatch(setSuccess(false, ''));
        }, 3000);
      }

      // if (data.type === 'new_llm_chat') {
      //   setLLMChats((_llmChats) => [..._llmChats, Object.assign({}, data.llm_chat)]);
      // }
    };

    return () => {
      webSocket.current.close();
    };
  }, []);


  return (
    <>
      {
        project.project_status === 'in_progress' ? (
          <CodeGenerationIndicator
            text={currentTaskText.length > 0 ? currentTaskText : 'Working'}
          />
        ) : <></>
      }
      <ConversationCard>
        <MessagesContainer>
          {llmChats.map((chat, i) => {
            return chat.llm_chat_type !== 'from_system' ? 
            <div key={'llm_chat__' + i}>
              {getMessageBody(chat)}
            </div> : <></>;
          })}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <SendingContainer>
          <NCTextArea
            background='transparent'
            placeholder={'Message Neuralcode Agent'}
            value={messageText}
            rows='2'
            onKeyDown={handleTextEnterKey}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
            <FlexSpacer />
            {
              project.project_status === 'in_progress' || isStreaming || isSendingMessage ? 
              <Oval
                color="#fff"
                secondaryColor='rgba(255,255,255,0.5)'
                height={40}
                width={40}
                strokeWidth={3}
              /> :
              <IconButton
                iconSize='35px'
                color='#fff'
                disabled={messageText.length === 0}
                onClick={() => sendMessage()}>
                <FaArrowAltCircleUp />
              </IconButton>
            }
          </div>
        </SendingContainer>
      </ConversationCard>
    </>
  );
}

export default ProjectChat;
