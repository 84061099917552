import React, { useState, useEffect, useRef } from 'react';
import {
  UserMenuCard
} from './UserMenuElements';
import { AvatarButton, AvatarBtnText } from '../Elements/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarImg } from '../Elements/SmallElements';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemEnd
} from '../Elements/List';
import { Divider } from '../Elements/SmallElements';
import { FiLogOut } from 'react-icons/fi';
import { logoutUser } from '../../utils/helpers';
import { useCookies } from 'react-cookie';
import { HiDotsHorizontal } from "react-icons/hi";


function UserMenu() {

  const [isOpen, setOpen] = useState(false);

  const user = useSelector(state => state.user);
  const accessToken = useSelector(state => state.accessToken);

  const ref = useRef();

  const dispatch = useDispatch();

  const [cookies, setCookie, removeCookie] = useCookies();

  const navigate = useNavigate();

  const closeOpenMenu = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', closeOpenMenu, { passive: true });

    return () => {
        window.removeEventListener('click', closeOpenMenu);
    };
  }, []);

  // const goToAccount = () => {
  //   navigate('/account');
  //   setOpen(false);
  // };

  return (
    <div style={{position: 'relative'}} ref={ref}>
      <ListItem clickable borderRadius="10px" onClick={() => setOpen(!isOpen)}
        isDarkMode>
        <div>
          <Avatar size={35} color={'var(--third-color)'}>
            {
              user.image === null ? user.name.substring(0, 1) : <AvatarImg src={user.image.thumb_sm} />
            }
          </Avatar>
        </div>
        <ListItemContent>
          <ListItemTitle fontSize="14px" color={'#fff'}>
            {user.name}
          </ListItemTitle>
          <ListItemSubtitle fontSize="12px" color={'rgba(255, 255, 255, 0.5)'}>
            {user.email.length > 20 ? user.email.substring(0,20) + '...' : user.email}
          </ListItemSubtitle>
        </ListItemContent>
        <ListItemEnd>
          <ListItemIcon iconSize='18px' color={'#fff'}>
            <HiDotsHorizontal />
          </ListItemIcon>
        </ListItemEnd>
      </ListItem>
      {
        isOpen ? 
        <UserMenuCard isRTL={false} isDarkMode>
          <ListItem clickable style={{margin: '10px 3px 5px'}} borderRadius="10px"
            isDarkMode>
            <div>
              <Avatar size={55} color={'var(--third-color)'}>
                {
                  user.image === null ? user.name.substring(0, 1) : <AvatarImg src={user.image.thumb_sm} />
                }
              </Avatar>
            </div>
            <ListItemContent>
              <ListItemTitle fontSize="15px" color={'#fff'}>
                {user.name}
              </ListItemTitle>
              <ListItemSubtitle fontSize="13px" color={'rgba(255, 255, 255, 0.5)'}>
                {user.email}
              </ListItemSubtitle>
            </ListItemContent>
          </ListItem>
          <Divider color={'rgba(255, 255, 255, 0.2)'} />
          <List style={{padding: '10px'}}>
            <ListItem clickable borderRadius="10px" onClick={() => logoutUser(accessToken, dispatch, removeCookie)}
              isDarkMode>
              <ListItemIcon iconSize="18px" color={'#fff'}>
                <FiLogOut />
              </ListItemIcon>
              <ListItemContent>
                <ListItemTitle fontWeight="400" fontSize="13px" color={'#fff'}>
                  Logout
                </ListItemTitle>
              </ListItemContent>
            </ListItem>
          </List>
        </UserMenuCard> : <></>
      }
    </div>
  );
}

export default UserMenu;