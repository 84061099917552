import styled from "styled-components";

export const UserMenuCard = styled.div`
  position: absolute;
  padding: 0;
  background-color: ${props => props.isDarkMode ? 'var(--dark-theme-background)' : '#fff'};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 20px;
  width: 300px;
  bottom: 0;
  z-index: 15;
`